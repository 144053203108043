
.app-welcome {
  margin: 2rem;
  padding-bottom: 4rem;
  text-align: center;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  a {
    color: rgba(43, 184, 255, 1);
    user-select: text;
  }

  .url {
    margin-bottom: 1rem;
  }

  .options {
    display: inline-block;
    margin-bottom: 1rem;

    .form-group {
      margin-bottom: 0.5rem;
    }
  }

  .code {
    display: inline-block;
    text-align: left;
    user-select: text;
    width: 75vw;

    background: #ccc;
    color: black;
    padding: 1rem;
    border-radius: 0.25rem;
  }
}
